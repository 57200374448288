<template>
  <div>
    <!-- <navbar/> -->
    <router-view/>
  </div>
</template>

<script>
  import navbar from '@/components/layouts/navbar1.vue';

  export default {

    components: {
      navbar,
    },
    
  }
</script>

<style lang="scss" scoped>

</style>