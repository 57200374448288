import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import defaultlayout from './layouts/default.vue';
Vue.component('default-layout', defaultlayout);

// FOR BUEFY
import Buefy from 'buefy';
Vue.use(Buefy,{
  defaultIconPack: 'fas',
  defaultContainerElement: '#content',
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
