
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index.vue'),
    //redirect: '/pmst',
  },
  {
    path: '/pmst',
    component: () => import('../views/pmst.vue'),
  },
  {
    path: '/inma',
    component: () => import('../views/inma.vue'),
  },
  {
    path: '/smed/painel',
    component: () => import('../views/smed/painel.vue'),
  },
  {
    path: '/smed/:idEscola',
    component: () => import('../views/smed/equipamentosLink.vue'),
  },
  {
    path: '/smed/:idEscola/equipamento',
    component: () => import('../views/smed/addEquipamento.vue'),
  },
  {
    path: '/smed/:idEscola/equipamento/:idEquipamento',
    component: () => import('../views/smed/addEquipamento.vue'),
  },
  {
    path: '/smed/:idEscola/computador',
    component: () => import('../views/smed/addComputador.vue'),
  },
  {
    path: '*',
    name: 'notfound',
    components:{
      default: ()=> import ("../views/index.vue")
      // default: ()=> import ("../views/http/error404.vue")
    }
  }
];


export default routes;